import './App.scss';
import jh1 from './images/jh1.png';
import PallotoneDesktop from './PallotoneDesktop';
import CompoundDesktop from './CompoundDesktop';
import pallotoneLogo from './images/pallotone-logo.svg';
import compoundLogo from './images/compound-logo.svg';
import facebookIcon from './social-icons/facebook.svg';
import youtubeIcon from './social-icons/youtube.svg';
import linkedinIcon from './social-icons/linkedin.svg';
import instagramIcon from './social-icons/instagram.svg';
import githubIcon from './social-icons/github.svg';
import ContactForm from './ContactForm';

function App() {
  return (
    <div className='app-wrapper'>
      <div className='masthead'>
        <div className='container flex-container'>
          <div className='masthead-text-wrapper'>
            <h1 className='my-name'>
              {' '}
              <span className='david-text'>DAVID</span> LEE{' '}
            </h1>
            <p className='section-paragraph'>
              {' '}
              Software engineer and UI designer.
              <br />
              Passionate about design, technology and music.{' '}
            </p>
            <div className='social-links'>
              <a
                className='social-link'
                href='https://www.github.com/davidleehere'
                target='_blank'
                rel='noreferrer'
              >
                <img src={githubIcon} alt='Github icon' />
              </a>
              <a
                className='social-link'
                href='https://www.facebook.com/davidleehere'
                target='_blank'
                rel='noreferrer'
              >
                <img src={facebookIcon} alt='Facebook icon' />
              </a>
              <a
                className='social-link'
                href='https://www.youtube.com/user/davidleetunes'
                target='_blank'
                rel='noreferrer'
              >
                <img src={youtubeIcon} alt='Youtube icon' />
              </a>
              <a
                className='social-link'
                href='https://www.instagram.com/davidleehere'
                target='_blank'
                rel='noreferrer'
              >
                <img src={instagramIcon} alt='Instagram icon' />
              </a>
            </div>
            {/* <a className="contact-button" href="mailto:davideo86@gmail.com">Say Hello</a> */}
          </div>
          <div className='jh1-wrapper'>
            <img src={jh1} className='jh1-image' alt='David Lee' />
          </div>
        </div>
      </div>
      <div className='project-section pallotone-section'>
        <div className='text-wrapper'>
          <img
            src={pallotoneLogo}
            className='pallotone-logo'
            alt='Pallotone logo'
          />
          <p className='section-paragraph'>
            Share music in a multitrack format.
          </p>
          <a
            className='view-project-link'
            href='https://pallotone.com'
            target='_blank'
            rel='noreferrer'
          >
            View project<span className='material-icons'>open_in_new</span>
          </a>
        </div>
        <div className='pallotone-desktop'>
          <PallotoneDesktop />
        </div>
      </div>
      <div className='project-section compound-section'>
        <div className='container'>
          <div className='text-wrapper'>
            <img
              src={compoundLogo}
              className='compound-logo'
              alt='Compound logo'
            />
            <p className='section-paragraph'>
              Earn interest, borrow against your assets, and participate in
              community governance.
            </p>
            <a
              className='view-project-link'
              href='https://v3-app.compound.finance'
              target='_blank'
              rel='noreferrer'
            >
              View project<span className='material-icons'>open_in_new</span>
            </a>
          </div>
          <div className='compound-hero-image'>
            <CompoundDesktop />
          </div>
        </div>
      </div>
      <div className='contact-section'>
        <div className='narrow-container'>
          <h2 className='contact-section-header'>Say Hello 👋</h2>
          <ContactForm />
        </div>
        <div className='container'>
          <div className='factoids'>
            <div className='factoid'>
              <span className='material-icons'>email</span>davideo86@gmail.com
            </div>
            <div className='factoid'>
              <span className='material-icons'>call</span>571-232-0439
            </div>
            <div className='factoid'>
              <span className='material-icons'>place</span>Fairfax, VA, USA
            </div>
            <div className='factoid social-links'>
              <a
                className='social-link'
                href='https://www.github.com/davidleehere'
                target='_blank'
                rel='noreferrer'
              >
                <img src={githubIcon} alt='Github icon' />
              </a>
              <a
                className='social-link'
                href='https://www.facebook.com/davidleehere'
                target='_blank'
                rel='noreferrer'
              >
                <img src={facebookIcon} alt='Facebook icon' />
              </a>
              <a
                className='social-link'
                href='https://www.youtube.com/user/davidleetunes'
                target='_blank'
                rel='noreferrer'
              >
                <img src={youtubeIcon} alt='Youtube icon' />
              </a>
              <a
                className='social-link'
                href='https://www.instagram.com/davidleehere'
                target='_blank'
                rel='noreferrer'
              >
                <img src={instagramIcon} alt='Instagram icon' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
